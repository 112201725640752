export const qMaritalStatus = [
  { name: 'Celibataire', value: 'celibataire', id: 0 },
  { name: 'Marié', value: 'marié', id: 1 },
  { name: 'Divoicé', value: 'divoicé', id: 2 },
  { name: 'Veuf ou Veuve', value: 'veuf', id: 3 },
];
export const children_number = [];
export const qSex = [{ name: 'Male', value: 'm', id: 0 }, { name: 'Femme',value: 'f', id: 1 }];
export const qModePaiemment = [{ name: 'Espèce', id: 0 }, { name: 'Viremment Banquaire', id: 1 }, { name: 'Mobile Money', id: 2 }];
export const qWorkplace = [
  { name: 'En entreprise', id: 0 },
  { name: 'A distance', id: 1 },
];
export const qStateJob = [
  { name: 'Disponible', id: 0 },
  { name: 'Absence', id: 1 },
  { name: 'En Congé', id: 2 },
  { name: 'En Vacance', id: 3 },
  { name: 'Frelance', id: 4 },
];

export const qRelations = [
  { name: 'Pére', id: 0 },
  { name: 'Mere', id: 1 },
  { name: 'Oncle', id: 2 },
  { name: 'Neuve', id: 3 },
  { name: 'Tuteur', id: 4 },
];