<template v-if="userData">
  <tr class="d-flex flex-row ">
    <td class="font-weight-bold bg-light-secondary w-50 py-1 d-flex justify-content-end">
      {{ name }}
    </td>
    <td class="py-1 w-75">{{ details === null ? 'Pas definie' : details }}</td>
  </tr>
</template>

<script>
// import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api';
import { BRow, BCol, BAlert, BLink } from 'bootstrap-vue';
// import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue';
// import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
// import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    // UserViewUserPlanCard,
    // UserViewUserPermissionsCard,
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('user'));
  },
  props: {
    name: String,
    details: String,
  },
  setup() {
    const userData = ref(null);

    return {
      userData,
    };
  },
};
</script>

<style></style>
