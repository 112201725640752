export const qEmployeProfileValidator = async (request) => {
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const digit = /^\d+$/;
    if(request.email.value !== '' &&  !regexEmail.test(request.email.value)){
        return {
            error: true,
            path : request.email.id,
            message : 'Viellez entrer un email valide'
        }
    }else if(request.children_number.value !== '' && !digit.test(request.children_number.value)){
        return {
            error: true,
            path : request.children_number.id,
            message : 'Viellez entrer un nombre valide'
        }
    }
    else{
        return {
            error: false,
            path : '',
            message : ''
        } 
    }
}


export const qEmployePaieValidator = async (request) => {
    const digit = /^\d+$/;
    if(request.salaire.value !== '' && !digit.test(request.salaire.value)){
        return {
            error: true,
            path : request.salaire.id,
            message : 'Viellez entrer un montant valide'
        }
    }
    else{
        return {
            error: false,
            path : '',
            message : ''
        } 
    }
}



export const qEmployeJobValidator = async (request) => {
    const digit = /^\d+$/;
  
        return {
            error: false,
            path : '',
            message : ''
        } 
    
}



export const qEmployeEmergencyValidator = async (request) => {
    const digit = /^\d+$/;
    if(request.contact_1.value !== '' && !digit.test(request.contact_1.value)){
        return {
            error: true,
            path : request.contact_1.id,
            message : 'Viellez entrer un numéro valide'
        }
    }
    else if(request.contact_2.value !== '' && !digit.test(request.contact_2.value)){
        return {
            error: true,
            path : request.contact_2.id,
            message : 'Viellez entrer un numéro valide'
        }
    }
    else{
        return {
            error: false,
            path : '',
            message : ''
        } 
    }
}


qEmployeEmergencyValidator