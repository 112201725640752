<template>
   <section>
      <q-header-details-employe :userData="userData" />

      <b-card no-body class="pb-2 px-2">
         <b-tabs pills card  class="flex justify-end justify-content-md-between pb-4">
            <!-- tab detail employé -->
            <b-tab no-body class="mx-1">
               <template #title class="flex">
                  <feather-icon
                     :id="`invoice-row-1-send-icon`"
                     icon="HomeIcon"
                     class="cursor-pointer"
                     size="16"
                  />
                  <div class="d-flex flex-row">
                     <span class="d-none d-md-flex">Détail sur l'employé </span>
                     <!-- <span style="margin-left: 4px">({{ qBillPayments.count }})</span> -->
                  </div>
               </template>

               <b-row class="">
                  <b-col lg="12">
                     <b-row class="flex-wrap">
                        <b-col lg="10">
                           <div class=" d-flex justify-content-between">
                              <!-- Modal pour ajouter une nouvelle taxe -->
                              <div class="d-flex align-content-center">
                                 <feather-icon size="24" icon="UserIcon" />
                                 <span class="ml-50 h3">Profile</span>
                              </div>
                              <span class=" font-weight-bold ">
                                 <feather-icon
                                    size="24"
                                    variant="relief-secondary"
                                    v-b-modal.update-profile
                                    class="ml-50 dt"
                                    icon="Edit3Icon"
                                 />
                              </span>
                           </div>

                           <b-card no-body>
                              <table class="table mt-50">
                                 <tbody>
                                    <q-tr-user-detail
                                       name="Adresse"
                                       :details="
                                          userData.localisation === null ? '' :
                                             userData.localisation.formatted_address
                                       "
                                    />
                                    <q-tr-user-detail
                                       name="Email"
                                       :details="userData.email"
                                    />
                                    <q-tr-user-detail
                                       name="Date de naissance"
                                       :details="userData.date_naissance"
                                    />
                                    <q-tr-user-detail
                                       name="Sexe"
                                       :details="userData.sexe"
                                    />
                                    <q-tr-user-detail
                                       name="Situation matrimoniale"
                                       :details="
                                          userData.situation_matrimoniale
                                       "
                                    />
                                    <q-tr-user-detail
                                       name=" Nombre d'enfant"
                                       :details="userData.nombre_enfant"
                                    />
                                    <q-tr-user-detail
                                       name="Téléphone"
                                       :details="userData.contact"
                                    />
                                 </tbody>
                              </table>
                           </b-card>
                        </b-col>

                        <b-col lg="10">
                           <div class=" d-flex justify-content-between">
                              <!-- Modal pour ajouter une nouvelle taxe -->
                              <div class="d-flex align-content-center">
                                 <feather-icon size="24" icon="LayersIcon" />
                                 <span class="ml-50 h3">Paie</span>
                              </div>
                              <span class=" font-weight-bold ">
                                 <feather-icon
                                    size="24"
                                    variant="relief-secondary"
                                    v-b-modal.update-paie
                                    class="ml-50 dt"
                                    icon="Edit3Icon"
                                 />
                              </span>
                           </div>

                           <b-card no-body>
                              <table class="table mt-50">
                                 <tbody class="text-align-left ">
                                    <q-tr-user-detail
                                       name="Salaire"
                                       :details="userData.salaire"
                                    />

                                    <q-tr-user-detail
                                       name="Prime"
                                       :details="'Depreciate'"
                                    />

                                    <q-tr-user-detail
                                       name="Numero d'assurance"
                                       :details="userData.no_assurance"
                                    />

                                    <q-tr-user-detail
                                       name="Mode de paiemment"
                                       :details="userData.mode_paiemment"
                                    />
                                 </tbody>
                              </table>
                           </b-card>
                        </b-col>

                        <b-col lg="10">
                           <div class=" d-flex justify-content-between">
                              <!-- Modal pour ajouter une nouvelle taxe -->
                              <div class="d-flex align-content-center">
                                 <feather-icon size="24" icon="BriefcaseIcon" />
                                 <span class="ml-50 h3">Emploi</span>
                              </div>
                              <span class=" font-weight-bold ">
                                 <feather-icon
                                    size="24"
                                    variant="relief-secondary"
                                    v-b-modal.update-job
                                    class="ml-50 dt"
                                    icon="Edit3Icon"
                                 />
                              </span>
                           </div>

                           <b-card no-body>
                              <table class="table mt-50">
                                 <tbody>
                                    <q-tr-user-detail
                                       name="Disponibilité de l'employer"
                                       :details="userData.etat"
                                    />
                                    <q-tr-user-detail
                                       name="Date d'embauche"
                                       :details="userData.date_embauche"
                                    />
                                    <q-tr-user-detail
                                       name="Lieu de travail"
                                       :details="userData.lieu_travail"
                                    />
                                    <q-tr-user-detail
                                       name="Departement"
                                       :details="userData.departement"
                                    />
                                    <q-tr-user-detail
                                       name="Poste occupé"
                                       :details="userData.profession"
                                    />
                                 </tbody>
                              </table>
                           </b-card>
                        </b-col>
                     </b-row>
                  </b-col>
               </b-row>
            </b-tab>

            <!-- end tab detail employé -->

            <b-tab no-body class="mx-1">
               <template #title class="flex">
                  <feather-icon
                     :id="`invoice-row-1-send-icon`"
                     icon="DollarSignIcon"
                     class="cursor-pointer"
                     size="16"
                  />
                  <div class="d-flex flex-row">
                     <span class="d-none d-md-flex">Paie & Prime </span>
                     <!-- <span style="margin-left: 4px">({{ qBillPayments.count }})</span> -->
                  </div>
               </template>

               <b-row class="">
                  <!-- Per Page -->

                  <b-col
                     cols="12"
                     md="6"
                     class="d-flex align-items-center flex-wrap justify-content-start mb-md-0 "
                  >
                     <b-button variant="primary" :to="{ name: 'FactureAdd' }">
                        <feather-icon icon="PlusIcon" class="mx-auto" />
                        Nouvelle Depense
                     </b-button>
                  </b-col>

                  <!-- Search -->

                  <b-col cols="12" md="6" class="">
                     <div class="d-flex align-items-center justify-content-end">
                        <b-input-group class="input-group-merge">
                           <b-input-group-prepend is-text>
                              <feather-icon icon="SearchIcon" />
                           </b-input-group-prepend>
                           <b-form-input
                              v-model="state.filtre"
                              class="d-inline-block mr-1"
                              placeholder="Rechercher par : nom d'article, type, prix valeur..."
                           />
                        </b-input-group>
                     </div>
                  </b-col>
               </b-row>
               <b-row>
                  <!-- Modal pour ajouter une nouvelle taxe -->
                  <!-- <feather-icon  icon="LayersIcon" />
                            <span class="w-25 font-weight-bold">Paie</span> -->

                  <!-- Tableau pour afficher les taxes -->
                  <div class="" style="width:100%">
                     <q-loader-table
                        :success="state.success"
                        :empty="state.empty"
                        :warring="state.warring"
                     />
                     <b-table
                        style=""
                        hover
                        responsive
                        primary-key="id"
                        :per-page="state.perPage"
                        :current-page="state.currentPage"
                        :items="paieEmploye"
                        :fields="tableColumns"
                        :filter="state.filter"
                        show-empty
                        empty-text="Aucun"
                        class=""
                        v-if="state.success === true"
                     >
                        <template #cell(montant_depense)="data">
                           <span>{{
                              formatter(parseInt(data.item.montant_depense))
                           }}</span>
                        </template>
                        <template #cell(created_at)="data">
                           <div class="py-1">
                              <span class="">{{
                                 format_date(data.item.created_at)
                              }}</span>
                           </div>
                        </template>
                     </b-table>
                  </div>
               </b-row>
            </b-tab>

            <b-tab no-body class="mx-1">
               <template #title class="flex">
                  <feather-icon
                     :id="`invoice-row-1-send-icon`"
                     icon="CornerRightDownIcon"
                     class="cursor-pointer"
                     size="16"
                  />
                  <div class="d-flex flex-row">
                     <span class="d-none d-md-flex">Depenses </span>
                     <!-- <span style="margin-left: 4px">({{ qBillPayments.count }})</span> -->
                  </div>
               </template>

               <b-row class="">
                  <!-- Per Page -->

                  <b-col
                     cols="12"
                     md="6"
                     class="d-flex align-items-center flex-wrap justify-content-start mb-md-0 "
                  >
                     <b-button variant="primary" :to="{ name: 'FactureAdd' }">
                        <feather-icon icon="PlusIcon" class="mx-auto" />
                        Nouvelle Depense
                     </b-button>
                  </b-col>

                  <!-- Search -->

                  <b-col cols="12" md="6" class="">
                     <div class="d-flex align-items-center justify-content-end">
                        <b-input-group class="input-group-merge">
                           <b-input-group-prepend is-text>
                              <feather-icon icon="SearchIcon" />
                           </b-input-group-prepend>
                           <b-form-input
                              v-model="state.filtre"
                              class="d-inline-block mr-1"
                              placeholder="Rechercher par : nom d'article, type, prix valeur..."
                           />
                        </b-input-group>
                     </div>
                  </b-col>
               </b-row>
               <b-row>
                  <!-- Modal pour ajouter une nouvelle taxe -->
                  <!-- <feather-icon  icon="LayersIcon" />
                            <span class="w-25 font-weight-bold">Paie</span> -->

                  <!-- Tableau pour afficher les taxes -->
                  <div class="" style="width:100%">
                     <q-loader-table
                        :success="state.success__d"
                        :empty="state.empty__d"
                        :warring="state.warring__d"
                     />
                     <b-table
                        style=""
                        hover
                        responsive
                        primary-key="id"
                        :per-page="state.perPage"
                        :current-page="state.currentPage"
                        :items="depenseEmploye"
                        :fields="tableColumns"
                        :filter="state.filter__d"
                        show-empty
                        empty-text="Aucun"
                        class=""
                        v-if="state.success__d === true"
                     >
                        <template #cell(montant_depense)="data">
                           <span>{{
                              formatter(parseInt(data.item.montant_depense))
                           }}</span>
                        </template>
                        <template #cell(created_at)="data">
                           <div class="py-1">
                              <span class="">{{
                                 format_date(data.item.created_at)
                              }}</span>
                           </div>
                        </template>
                     </b-table>
                  </div>
               </b-row>
            </b-tab>

            <!-- End tab Liste de chèque de paie -->

            <!-- End tab coordonnée d'urgence -->

            <!-- tab coordonnée d'urgence -->
            <b-tab no-body class="">
               <template #title class="flex">
                  <feather-icon
                     :id="`invoice-row-1-send-icon`"
                     icon="PhoneCallIcon"
                     class="cursor-pointer"
                     size="16"
                  />
                  <div class="d-flex flex-row">
                     <span class="d-none d-md-flex">Coordonnée d'urgence </span>
                     <!-- <span style="margin-left: 4px">({{ qBillPayments.count }})</span> -->
                  </div>
               </template>

               <b-row>
                  <b-col lg="10" md="12">
                     <b-card>
                        <div class=" d-flex justify-content-between mb-2">
                           <!-- Modal pour ajouter une nouvelle taxe -->
                           <div class="d-flex align-content-center">
                              <feather-icon size="24" icon="FeatherIcon" />
                              <span class="ml-50 h3">Coordonnée I</span>
                           </div>
                           <span class=" font-weight-bold ">
                              <feather-icon
                                 size="24"
                                 variant="relief-secondary"
                                 v-b-modal.update-emergency
                                 class="ml-50 dt"
                                 icon="Edit3Icon"
                              />
                           </span>
                        </div>
                        <table class="table">
                           <tbody>
                              <q-tr-user-detail
                                 name="Nom complet"
                                 :details="userData.full_name_urgence"
                              />
                              <q-tr-user-detail
                                 name="Contact 1 "
                                 :details="userData.contact_1"
                              />
                              <q-tr-user-detail
                                 name="Contact 2"
                                 :details="userData.contact_2"
                              />
                              <q-tr-user-detail
                                 name="Relation avec l'employé"
                                 :details="userData.relation"
                              />
                              <q-tr-user-detail
                                 name="Lieu de residence"
                                 :details="userData.localisation_urgence"
                              />
                           </tbody>
                        </table>
                     </b-card>
                  </b-col>
               </b-row>
            </b-tab>

            <!-- End tab congés et Abscence -->
         </b-tabs>

         <!-- 
        -
        -
        -
        -Modal pour mettre à jour un employe @Paie -->
         <b-modal
            id="update-paie"
            cancel-variant="outline-secondary"
            ok-title="Créer"
            cancel-title="Annuler"
            centered
            title="Modifier"
            @ok="updatePaie"
         >
            <b-form class="auth-register-form mt-2">
               <!-- nom -->
               <b-form-group
                  :label="updatePaieData.salaire.label"
                  :label-for="'profile-' + updatePaieData.salaire.id"
               >
                  <b-form-input
                     :id="'profile-' + updatePaieData.salaire.id"
                     v-model="updatePaieData.salaire.value"
                     :name="'profile-' + updatePaieData.salaire.label"
                     :placeholder="updatePaieData.salaire.placeholder"
                  />
                  <small
                     v-if="errorInput.path === updatePaieData.salaire.id"
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <b-form-group
                  :label="updatePaieData.no_assurance.label"
                  :label-for="'profile-' + updatePaieData.no_assurance.id"
               >
                  <b-form-input
                     :id="'profile-' + updatePaieData.no_assurance.id"
                     v-model="updatePaieData.no_assurance.value"
                     :name="'profile-' + updatePaieData.no_assurance.label"
                     :placeholder="updatePaieData.no_assurance.placeholder"
                  />
                  <small
                     v-if="errorInput.path === updatePaieData.no_assurance.id"
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <b-form-group
                  :label="updatePaieData.mode_paiemment.label"
                  :label-for="'profile-' + updatePaieData.mode_paiemment.id"
               >
                  <v-select
                     v-model="updatePaieData.mode_paiemment.value"
                     :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                     label="name"
                     :options="qModePaiemment"
                     :placeholder="updatePaieData.mode_paiemment.placeholder"
                  />
                  <small
                     v-if="
                        errorInput.path === updatePaieData.mode_paiemment.label
                     "
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>
            </b-form>

            <template #modal-footer>
               <b-button
                  :disabled="state.loading === true ? true : false"
                  variant="primary"
                  @click="updatePaie"
               >
                  <span v-if="state.loading === false">Mettre à jour</span>
                  <b-spinner
                     v-if="state.loading === true"
                     label="Spinning"
                  ></b-spinner>
               </b-button>
            </template>
         </b-modal>

         <!-- 
        -
        -
        -
        -Modal pour mettre à jour un employe @Profie -->
         <b-modal
            id="update-profile"
            cancel-variant="outline-secondary"
            ok-title="Modifier"
            cancel-title="Annuler"
            centered
            :title="'Modifier Profile - @'"
            @ok="updateProfile"
         >
            <b-form class="auth-register-form mt-2">
               <!-- nom -->
               <b-form-group
                  :label="updateProfileData.email.label"
                  :label-for="'profile-' + updateProfileData.email.id"
               >
                  <b-form-input
                     :id="'profile-' + updateProfileData.email.id"
                     v-model="updateProfileData.email.value"
                     :name="'profile-' + updateProfileData.email.label"
                     :placeholder="updateProfileData.email.placeholder"
                  />
                  <small
                     v-if="errorInput.path === updateProfileData.email.id"
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <b-form-group
                  :label="updateProfileData.marital_status.label"
                  :label-for="'profile-' + updateProfileData.marital_status.id"
               >
                  <v-select
                     v-model="updateProfileData.marital_status.value"
                     :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                     label="name"
                     :options="qMaritalStatus"
                     :placeholder="updateProfileData.marital_status.placeholder"
                  />
                  <small
                     v-if="
                        errorInput.path ===
                           updateProfileData.marital_status.label
                     "
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <b-form-group
                  :label="updateProfileData.children_number.label"
                  :label-for="'profile-' + updateProfileData.children_number.id"
               >
                  <b-form-input
                     :id="'profile-' + updateProfileData.children_number.id"
                     v-model="updateProfileData.children_number.value"
                     :name="
                        'profile-' + updateProfileData.children_number.label
                     "
                     :placeholder="
                        updateProfileData.children_number.placeholder
                     "
                  />
                  <small
                     v-if="
                        errorInput.path === updateProfileData.children_number.id
                     "
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <b-form-group
                  :label="updateProfileData.born.label"
                  :label-for="'profile-' + updateProfileData.born.id"
               >
                  <flat-pickr
                     class="form-control"
                     :id="'profile-' + updateProfileData.born.id"
                     v-model="updateProfileData.born.value"
                     :name="'profile-' + updateProfileData.born.label"
                     :placeholder="updateProfileData.born.placeholder"
                  />

                  <small
                     v-if="errorInput.path === updateProfileData.born.label"
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <b-form-group
                  :label="updateProfileData.sex.label"
                  :label-for="'profile-' + updateProfileData.sex.id"
               >
                  <v-select
                     v-model="updateProfileData.sex.value"
                     :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                     label="name"
                     :options="qSex"
                     :placeholder="updateProfileData.sex.placeholder"
                  />
                  <small
                     v-if="errorInput.path === updateProfileData.sex.label"
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>
            </b-form>

            <template #modal-footer>
               <b-button
                  :disabled="state.loading === true ? true : false"
                  variant="primary"
                  @click="updateProfile"
               >
                  <span v-if="state.loading === false">Mettre à jour</span>
                  <b-spinner
                     v-if="state.loading === true"
                     label="Spinning"
                  ></b-spinner>
               </b-button>
            </template>
         </b-modal>

         <!-- 
        -
        -
        -
        -Modal pour mettre à jour un employe @JOB -->
         <b-modal
            id="update-job"
            cancel-variant="outline-secondary"
            ok-title="Modifier"
            cancel-title="Annuler"
            centered
            :title="'Modifier Emploi - @' + userData.nom"
            @ok="updateJob"
         >
            <b-form class="auth-register-form mt-2">
               <b-form-group
                  :label="updateJobData.etat.label"
                  :label-for="'profile-' + updateJobData.etat.id"
               >
                  <v-select
                     v-model="updateJobData.etat.value"
                     :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                     label="name"
                     :options="qStateJob"
                     :placeholder="updateJobData.etat.placeholder"
                  />
                  <small
                     v-if="errorInput.path === updateJobData.etat.label"
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <b-form-group
                  :label="updateJobData.date_embauche.label"
                  :label-for="'profile-' + updateJobData.etat.id"
               >
                  <flat-pickr
                     class="form-control"
                     :id="'profile-' + updateJobData.date_embauche.id"
                     v-model="updateJobData.date_embauche.value"
                     :name="'profile-' + updateJobData.date_embauche.label"
                     :placeholder="updateJobData.date_embauche.placeholder"
                  />

                  <small
                     v-if="
                        errorInput.path === updateJobData.date_embauche.label
                     "
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <b-form-group
                  :label="updateJobData.lieu_travail.label"
                  :label-for="'profile-' + updateJobData.lieu_travail.id"
               >
                  <v-select
                     v-model="updateJobData.lieu_travail.value"
                     :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                     label="name"
                     :options="qWorkplace"
                     selected
                     :placeholder="updateJobData.lieu_travail.placeholder"
                  />
                  <small
                     v-if="errorInput.path === updateJobData.lieu_travail.label"
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <b-form-group
                  :label="updateJobData.departement.label"
                  :label-for="'profile-' + updateJobData.departement.id"
               >
                  <b-form-input
                     :id="'profile-' + updateJobData.departement.id"
                     v-model="updateJobData.departement.value"
                     :name="'profile-' + updateJobData.departement.label"
                     :placeholder="updateJobData.departement.placeholder"
                  />
                  <small
                     v-if="errorInput.path === updateJobData.departement.id"
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <b-form-group
                  :label="updateJobData.profession.label"
                  :label-for="'profile-' + updateJobData.profession.id"
               >
                  <b-form-input
                     :id="'profile-' + updateJobData.profession.id"
                     v-model="updateJobData.profession.value"
                     :name="'profile-' + updateJobData.profession.label"
                     :placeholder="updateJobData.profession.placeholder"
                  />
                  <small
                     v-if="errorInput.path === updateJobData.profession.id"
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>
            </b-form>

            <template #modal-footer>
               <b-button
                  :disabled="state.loading === true ? true : false"
                  variant="primary"
                  @click="updateJob"
               >
                  <span v-if="state.loading === false">Mettre à jour</span>
                  <b-spinner
                     v-if="state.loading === true"
                     label="Spinning"
                  ></b-spinner>
               </b-button>
            </template>
         </b-modal>

         <!-- 
        -
        -
        -
        -Modal pour mettre à jour un employe @JOB -->
         <b-modal
            id="update-emergency"
            cancel-variant="outline-secondary"
            ok-title="Modifier"
            cancel-title="Annuler"
            centered
            :title="'Modifier Les coordonneés - @' + userData.nom"
            @ok="updateEmergency"
         >
            <b-form class="auth-register-form mt-2">
               <b-form-group
                  :label="updateEmergencyData.full_name_urgence.label"
                  :label-for="
                     'profile-' + updateEmergencyData.full_name_urgence.id
                  "
               >
                  <b-form-input
                     :id="'profile-' + updateEmergencyData.full_name_urgence.id"
                     v-model="updateEmergencyData.full_name_urgence.value"
                     :name="
                        'profile-' + updateEmergencyData.full_name_urgence.label
                     "
                     :placeholder="
                        updateEmergencyData.full_name_urgence.placeholder
                     "
                  />
                  <small
                     v-if="
                        errorInput.path ===
                           updateEmergencyData.full_name_urgence.id
                     "
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <b-form-group
                  :label="updateEmergencyData.contact_1.label"
                  :label-for="'profile-' + updateEmergencyData.contact_1.id"
               >
                  <b-form-input
                     :id="'profile-' + updateEmergencyData.contact_1.id"
                     v-model="updateEmergencyData.contact_1.value"
                     :name="'profile-' + updateEmergencyData.contact_1.label"
                     :placeholder="updateEmergencyData.contact_1.placeholder"
                  />
                  <small
                     v-if="errorInput.path === updateEmergencyData.contact_1.id"
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <b-form-group
                  :label="updateEmergencyData.contact_2.label"
                  :label-for="'profile-' + updateEmergencyData.contact_2.id"
               >
                  <b-form-input
                     :id="'profile-' + updateEmergencyData.contact_2.id"
                     v-model="updateEmergencyData.contact_2.value"
                     :name="'profile-' + updateEmergencyData.contact_2.label"
                     :placeholder="updateEmergencyData.contact_2.placeholder"
                  />
                  <small
                     v-if="errorInput.path === updateEmergencyData.contact_2.id"
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <b-form-group
                  :label="updateEmergencyData.relation.label"
                  :label-for="'profile-' + updateEmergencyData.relation.id"
               >
                  <v-select
                     :id="'profile-' + updateEmergencyData.relation.id"
                     v-model="updateEmergencyData.relation.value"
                     :name="'profile-' + updateEmergencyData.relation.label"
                     :placeholder="updateEmergencyData.relation.placeholder"
                     :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                     label="name"
                     :options="qRelations"
                  />
                  <small
                     v-if="errorInput.path === updateEmergencyData.relation.id"
                     class="text-danger"
                  >
                     {{ errorInput.message }}
                  </small>
               </b-form-group>

               <!-- Localisation -->
               <b-form-group
                  label="Localisation"
                  label-for="autocomplete_search"
               >
                  <b-form-input
                     id="autocomplete_search"
                     @input="initialize"
                     name="autocomplete_search"
                     type="text"
                     v-model="updateEmergencyData.localisation_urgence.value"
                     placeholder="Abidjan, Angré RCI 0089 BP 00225"
                  />
                  <input type="hidden" name="lat" />
                  <input type="hidden" name="long" />
                  <small v-if="errorInput.path === 'name'" class="text-danger">
                     {{ errorInput.message }}
                  </small>
               </b-form-group>
            </b-form>

            <template #modal-footer>
               <b-button
                  :disabled="state.loading === true ? true : false"
                  variant="primary"
                  @click="updateEmergency"
               >
                  <span v-if="state.loading === false">Mettre à jour</span>
                  <b-spinner
                     v-if="state.loading === true"
                     label="Spinning"
                  ></b-spinner>
               </b-button>
            </template>
         </b-modal>
      </b-card>

      {{ __NEXT }}
   </section>
</template>

<script>
import {
   BTable,
   BTableLite,
   BCard,
   BFormGroup,
   BButton,
   BAvatar,
   BRow,
   BCol,
   BModal,
   BFormInput,
   VBModal,
   BForm,
   BLink,
   BFormCheckbox,
   BInputGroup,
   BInputGroupAppend,
   BImg,
   BTabs,
   BTab,
   BCardText,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import vSelect from 'vue-select';
import URL from '@/views/pages/request';
import axios from 'axios';
import { VueTelInput } from 'vue-tel-input';
import flatPickr from 'vue-flatpickr-component';
import {
   qMaritalStatus,
   qSex,
   qModePaiemment,
   qStateJob,
   qWorkplace,
   qRelations,
} from './qDetailsEmploye___providers';

import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
   reactive,
   ref,
   onMounted,
   toRef,
   computed,
} from '@vue/composition-api';
import QLoaderTable from '@/components/__partials/loaders/qLoaderTable.vue';
import QTrUserDetail from './qTrUserDetail.vue';
import {
   qEmployeProfileValidator,
   qEmployePaieValidator,
   qEmployeJobValidator,
   qEmployeEmergencyValidator,
} from '@/utils/validators/employeValidate';
import QHeaderDetailsEmploye from './qHeaderDetailsEmploye.vue';
import qDeviseUtils from '@/utils/qDeviseUtils';
import { qDecrypted } from '@/services/qeCrypt';
import moment from 'moment';
import { toast_sucess } from '@/utils/qToast';
// import useUsersList from '../users-list/useUsersList'

export default {
   components: {
      BTable,
      flatPickr,
      BCard,
      BButton,
      BRow,
      BCol,
      BAvatar,
      VueTelInput,
      vSelect,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BModal,
      BFormGroup,
      VBModal,
      BForm,
      BImg,
      BLink,
      BForm,
      BFormCheckbox,
      BInputGroup,
      BInputGroupAppend,
      // validations
      ValidationProvider,
      ValidationObserver,
      BTabs,
      BTab,
      BCardText,
      BCardCode,
      BTableLite,
      QLoaderTable,
      QTrUserDetail,
      QHeaderDetailsEmploye,
   },
   directives: {
      Ripple,
   },
   props: ['userData'],
   mixins: [togglePasswordVisibility],

   async mounted() {
      document.title = 'Détail employé - Ediqia';

      try {
         await axios
            .get(URL.EMPLOYE_LIST)
            .then((response) => {
               // this.users = response.data.listeEmploye;
               this.roles = response.data.listeRole;
               console.log(this.returnData);
            })
            .catch((error) => {
               console.log(error.response.data.errors);
            });
         // for (let index = 0; index < this.returnData.data[0].length; index++) {
         //     this.users.push(this.returnData.data[0][index]);
         // }
      } catch (error) {
         console.log(error);
      }
   },

   computed: {
      passwordToggleIcon() {
         return this.passwordFieldType === 'password'
            ? 'EyeIcon'
            : 'EyeOffIcon';
      },
   },

   setup(props, { root }) {
      const state = reactive({
         perPage: 30,
         currentPage: 1,
         filter: '',
         success: false,
         empty: false,
         warring: false,
         loading: false,

         success__d: false,
         empty__d: false,
         warring__d: false,
         loading__d: false,
         filter__d: ''
      });

      const errorInput = reactive({
         path: '',
         message: '',
      });


      const employePayments = ref([]);
      const tableColumns = reactive([
         {
            key: 'libelle',
            label: 'Libelle',
         },
         {
            key: 'montant_depense',
            label: 'Salaire',
         },
         {
            key: 'type_depense',
            label: 'type de depense',
         },
         {
            key: 'created_at',
            label: 'Pour le mois',
         },
      ]);
      const perPageOptions = [30, 50, 100, 150, 250, 500];
      const depenseEmploye = ref([]);
      const paieEmploye = ref([]);

      onMounted(() => {
         google.maps.event.addDomListener(window, 'load', initialize);
         getDepenseEmployer();
      });

      const initialize = () => {
         var input = document.getElementById('autocomplete_search');
         var autocomplete = new google.maps.places.Autocomplete(input);
         autocomplete.addListener('place_changed', function() {
            var place = autocomplete.getPlace();
            // place variable will have all the information you are looking for.
            $('#lat').val(place.geometry['location'].lat());
            $('#long').val(place.geometry['location'].lng());
            localStorage.setItem('place_employer', JSON.stringify(place));
         });
      };

      // FUNCTION TO CONVERT NUMBER INTO CURRENCY
      const formatter = (amount) => {
         const dataToDecrypt = localStorage.getItem('entreprise_info');
         return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
      };
      const format_date = (value) => {
         if (value) {
            return moment(String(value)).format('DD-MM-YYYY');
         }
      };

      const GIVE = ref(false);
      const GIVE__d = ref(false);

      const __NEXT = computed(() => {
         if (GIVE.value === true) {
            paieEmploye.value.length === 0
               ? ((state.empty = true), (state.success = false))
               : ((state.success = true), (state.empty = false));

                depenseEmploye.value.length === 0
               ? ((state.empty__d = true), (state.success__d = false))
               : ((state.success__d = true), (state.empty__d = false));
         }  
         return;
      });

      /***
      GET ALL BILL PAYMENTS
      @method > GET
      @variable > depenseEmployer
      @function > getDepenseEmployer
      @return > Array<Object>
    */
      const getDepenseEmployer = async () => {
         try {
            const { data } = await axios.post(URL.DEPENSE_LIST);
            if (data.depense) {
               let __uGet = [];
               GIVE__d.value = true;
               GIVE.value = true;
               for (let i = 0; i < data.depense.length; i++) {
                  const deps = data.depense[i];
                  if (deps.employe_id !== null && deps.employe_id === props.userData.id) {
                     __uGet.push(deps);
                  }
               }
               paieEmploye.value = __uGet.filter((paie) => {
                  return (paie.type_depense === 'Prime' ||
                     paie.type_depense === 'Salaire')
               });
               depenseEmploye.value = __uGet.filter((depense) => {
                  return (
                     depense.type_depense != 'Prime' ||
                     depense.type_depense != 'Salaire'
                  )
               });
               paieEmploye.value.reverse()
               depenseEmploye.value.reverse()
            }
         } catch (error) {
            console.log(error);
         }
      };

      /***
    GET ALL BILL PAYMENTS
    @method > POST
    @variable > updatePaieData
    @function > updatePaie
    @return > Array<Object>
  */
      const updatePaieData = reactive({
         salaire: {
            id: 'saliare',
            label: 'Salaire',
            value: props.userData.salaire,
            placeholder: "Entrez le salire de l'employer",
         },
         no_assurance: {
            id: 'no_assurance',
            label: "Numéro d'assurance",
            value: props.userData.no_assurance,
            placeholder: "Entrez le numéro d'assurance",
         },
         mode_paiemment: {
            id: 'mode_paiemment',
            label: 'Mode de paiemment',
            value: props.userData.mode_paiemment,
            placeholder: 'Entrez le mode de paiemment',
         },
      });
      const updatePaie = async () => {
         try {
            state.loading = true;

            const { error, path, message } = await qEmployePaieValidator(
               updatePaieData
            );

            if (!error) {
               const uData = {
                  id: props.userData.id,
                  salaire: updatePaieData.salaire.value,
                  no_assurance: updatePaieData.no_assurance.value,
                  mode_paiemment:
                     updatePaieData.mode_paiemment.value === null ||
                     updatePaieData.mode_paiemment.value === ''
                        ? ''
                        : updatePaieData.mode_paiemment.value['name'],
               };

               const { data } = await axios.post(
                  URL.UPDATE_PAIE_EMPLOYE,
                  uData
               );
               if (data) {
                  props.userData.salaire = updatePaieData.salaire.value;
                  props.userData.no_assurance =
                     updatePaieData.no_assurance.value;
                  props.userData.mode_paiemment =
                     updatePaieData.mode_paiemment.value === null ||
                     updatePaieData.mode_paiemment.value === ''
                        ? ''
                        : updatePaieData.mode_paiemment.value['name'];
                  state.loading = false;
                  errorInput.path = path;
                  errorInput.message = message;
                  console.log(props.userData);
                  localStorage.setItem('user', JSON.stringify(props.userData));
               }
            } else {
               state.loading = false;
               errorInput.path = path;
               errorInput.message = message;
            }
         } catch (error) {
            state.loading = false;
            console.log(error);
         }
      };

      /***
    GET ALL BILL PAYMENTS
    @method > POST
    @variable > updateJobData
    @function > updateJob
    @return > Array<Object>
  */
      const updateJobData = reactive({
         etat: {
            id: 'etat',
            label: 'Disponibilité',
            value: props.userData.etat,
            placeholder: 'Entrez la disponible',
         },
         date_embauche: {
            id: 'date_embauche',
            label: "Date d'embauche",
            value: props.userData.date_embauche,
            placeholder: "Entrez la date d'embauche",
         },
         lieu_travail: {
            id: 'lieu_travail',
            label: 'Lieu de travail',
            value: props.userData.lieu_travail,
            placeholder: 'Entrez le lieu de travail',
         },
         departement: {
            id: 'departement',
            label: 'Departement',
            value: props.userData.departement,
            placeholder: 'Entrez le departement',
         },
         profession: {
            id: 'profession',
            label: 'Poste occupé',
            value: props.userData.profession,
            placeholder: 'Entrez la profession',
         },
      });

      const updateJob = async () => {
         try {
            state.loading = true;

            const { error, path, message } = await qEmployeJobValidator(
               updateJobData
            );

            if (!error) {
               const uData = {
                  id: props.userData.id,
                  etat:
                     updateJobData.etat.value === null || updateJobData.etat.value === ''
                        ? ''
                        : updateJobData.etat.value['name'],
                  date_embauche: updateJobData.date_embauche.value,
                  lieu_travail:
                     updateJobData.lieu_travail.value === null || updateJobData.lieu_travail.value === ''
                        ? ''
                        : updateJobData.lieu_travail.value['name'],
                  departement: updateJobData.departement.value,
                  profession: updateJobData.profession.value,
               };

               const { data } = await axios.post(URL.UPDATE_JOB_EMPLOYE, uData);
               if (data) {
                  props.userData.etat =
                     updateJobData.etat.value === null || updateJobData.etat.value === ''
                        ? ''
                        : updateJobData.etat.value['name'];
                  props.userData.date_embauche =
                     updateJobData.date_embauche.value;
                  props.userData.lieu_travail =
                     updateJobData.lieu_travail.value === null || updateJobData.lieu_travail.value === ''
                        ? ''
                        : updateJobData.lieu_travail.value['name'];
                  props.userData.departement = updateJobData.departement.value;
                  props.userData.profession = updateJobData.profession.value;

                  root.$bvModal.hide('update-job');
                  toast_sucess(root, 'success', 'top-right', 'Employé');

                  state.loading = false;
                  errorInput.path = path;
                  errorInput.message = message;
                  console.log(props.userData);
                  localStorage.setItem('user', JSON.stringify(props.userData));
               }
            } else {
               state.loading = false;
               errorInput.path = path;
               errorInput.message = message;
            }
         } catch (error) {
            state.loading = false;
            console.log(error);
         }
      };

      /***
    GET ALL BILL PAYMENTS
    @method > POST
    @variable > [updateProfileData]
    @function > updateProfile
    @return > Array<Object>
  */
      const updateProfileData = reactive({
         email: {
            id: 'email',
            label: 'Email',
            value: props.userData.email,
            placeholder: 'Email',
         },
         sex: {
            id: 'sex',
            label: 'Sex',
            value: props.userData.sexe,
            placeholder: 'Sex',
         },
         born: {
            id: 'Date_de_naissance',
            label: 'Date de naissance',
            value: props.userData.date_naissance,
            placeholder: 'Date de naissance',
         },
         marital_status: {
            id: 'marital_status',
            label: 'Situtiation Matrimoniale',
            value: props.userData.situation_matrimoniale,
            placeholder: 'Entrez la situtiation matrimoniale',
         },
         children_number: {
            id: 'children_number',
            label: "Nombre d'nombre",
            value: props.userData.nombre_enfant,
            placeholder: "Entrez le nombre d'enfant",
         },
      });
      const updateProfile = async () => {
         try {
            state.loading = true;

            const { error, path, message } = await qEmployeProfileValidator(
               updateProfileData
            );

            if (!error) {
               const uData = {
                  id: props.userData.id,
                  email: updateProfileData.email.value,
                  sexe:
                     updateProfileData.sex.value === null || updateProfileData.sex.value === ''
                        ? ''
                        : updateProfileData.sex.value['name'],
                  date_naissance: updateProfileData.born.value,
                  situation_matrimoniale:
                     updateProfileData.marital_status.value === null || updateProfileData.sex.value === ''
                        ? ''
                        : updateProfileData.marital_status.value['name'],
                  nombre_enfant: updateProfileData.children_number.value,
               };

               const { data } = await axios.post(
                  URL.UPDATE_PROFIL_EMPLOYE,
                  uData
               );
               if (data) {
                  props.userData.email = updateProfileData.email.value;
                  props.userData.sexe =
                     updateProfileData.sex.value === null || updateProfileData.sex.value === ''
                        ? ''
                        : updateProfileData.sex.value['name'];
                  props.userData.date_naissance = updateProfileData.born.value;
                  props.userData.situation_matrimoniale =
                     updateProfileData.marital_status.value === null || updateProfileData.marital_status.value === ''
                        ? ''
                        : updateProfileData.marital_status.value['name'];
                  props.userData.nombre_enfant =
                     updateProfileData.children_number.value;

                  root.$bvModal.hide('update-profile');
                  toast_sucess(root, 'success', 'top-right', 'Employé');

                  state.loading = false;
                  errorInput.path = path;
                  errorInput.message = message;
                  console.log(props.userData);
                  localStorage.setItem('user', JSON.stringify(props.userData));
               }
            } else {
               state.loading = false;
               errorInput.path = path;
               errorInput.message = message;
            }
         } catch (error) {
            state.loading = false;
            console.log(error);
         }
      };

      /***
    GET ALL BILL PAYMENTS
    @method > POST
    @variable > [updateEmergencyData]
    @function > updateEmergency
    @return > Array<Object>
  */
      const updateEmergencyData = reactive({
         full_name_urgence: {
            id: 'full_name_urgence',
            label: 'Nom complet',
            value: props.userData.full_name_urgence,
            placeholder: 'Entrez le nom complet',
         },
         contact_1: {
            id: 'contact_1',
            label: 'Contact 1',
            value: props.userData.contact_1,
            placeholder: 'Ex: 002250707070707',
         },
         contact_2: {
            id: 'contact_2',
            label: 'Contact 2',
            value: props.userData.contact_2,
            placeholder: 'Ex: 002250707070707',
         },
         relation: {
            id: 'relation',
            label: 'Relation',
            value: props.userData.relation,
            placeholder: "Entrez la relation avec l'employé ",
         },
         localisation_urgence: {
            id: 'localisation_urgence',
            label: 'Localisation',
            value: props.userData.localisation_urgence,
            placeholder: 'Entrez la localisation',
         },
      });
      const updateEmergency = async () => {
         try {
            state.loading = true;

            const { error, path, message } = await qEmployeEmergencyValidator(
               updateEmergencyData
            );

            if (!error) {
               const place = JSON.parse(localStorage.getItem('place_employer'));
               console.log(updateEmergencyData.relation.value);
               const uData = {
                  id: props.userData.id,
                  full_name_urgence:
                     updateEmergencyData.full_name_urgence.value,
                  contact_1: updateEmergencyData.contact_1.value,
                  contact_2: updateEmergencyData.contact_2.value,
                  relation:
                     updateEmergencyData.relation.value === '' ||
                     updateEmergencyData.relation.value === null
                        ? ''
                        : updateEmergencyData.relation.value['name'],
                  localisation_urgence: place.formatted_address,
               };

               const { data } = await axios.post(
                  URL.UPDATE_EMERGENCY_EMPLOYE,
                  uData
               );
               if (data) {
                  props.userData.full_name_urgence =
                     updateEmergencyData.full_name_urgence.value;
                  props.userData.contact_1 =
                     updateEmergencyData.contact_1.value;

                  props.userData.contact_2 =
                     updateEmergencyData.contact_2.value;

                  props.userData.relation =
                     updateEmergencyData.relation.value === '' ||
                     updateEmergencyData.relation.value === null
                        ? ''
                        : updateEmergencyData.relation.value['name'];

                  props.userData.localisation_urgence = place.formatted_address;
                  state.loading = false;

                  root.$bvModal.hide('update-emergency');
                  toast_sucess(root, 'success', 'top-right', 'Employé');

                  errorInput.path = path;
                  errorInput.message = message;
                  console.log(props.userData);
                  localStorage.setItem('user', JSON.stringify(props.userData));
               }
            } else {
               state.loading = false;
               errorInput.path = path;
               errorInput.message = message;
            }
         } catch (error) {
            state.loading = false;
            console.log(error);
         }
      };

      return {
         perPageOptions,
         state,
         tableColumns,
         employePayments,
         avatarText,
         errorInput,
         updateProfile,
         updateProfileData,
         qModePaiemment,
         qMaritalStatus,
         qSex,
         updatePaie,
         updatePaieData,
         updateJobData,
         updateJob,
         qStateJob,
         qRelations,
         qWorkplace,
         updateEmergency,
         updateEmergencyData,
         initialize,
         formatter,
         depenseEmploye,
         GIVE__d,
         GIVE,
         __NEXT,
         format_date,
         paieEmploye,
      };
   },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
