import axios from 'axios';
import URL from './../views/pages/request/index';

class qDeviseUtils {
  constructor() {
  }

  async getDevis__user() {
    try {
      const { data } = await axios.get(URL.ENTREPRISE_INFO);

      if (data) {
         
        return  data[0].devise[0].libelle;
      }
    } catch (error) {
      console.log(error);
    }
  }

  formatter(devise, amount) {
    devise = devise.toLowerCase()
    if (devise === 'xof') {
      return new Intl.NumberFormat('de-DE', {
        currency: 'XOF',
        style: 'currency',
        minimumFractionDigits: 2,
      }).format(amount);
    } else if (devise === 'xaf') {
      return new Intl.NumberFormat('de-DE', {
        currency: 'XAF',
        style: 'currency',
        minimumFractionDigits: 2,
      }).format(amount);
    } else if (devise === 'dollar') {
      return new Intl.NumberFormat('ja-JP', {
        currency: 'USD',
        style: 'currency',
        minimumFractionDigits: 2,
      }).format(amount);
    } else if (devise === 'euro') {
      return new Intl.NumberFormat('de-DE', {
        currency: 'EUR',
        style: 'currency',
        minimumFractionDigits: 2,
      }).format(amount);
    } else if (devise === 'cedi') {
      return new Intl.NumberFormat('se-DE', {
        currency: 'GHS',
        style: 'currency',
        minimumFractionDigits: 2,
      }).format(amount);
    }
  }
}

export default new qDeviseUtils();
