<template>
 <b-card>
      <b-container class="py-2">
        <b-row>
          <!-- User Info: Left col -->
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-start">
              <b-avatar
                :src="userData.avatar"
                size="104px"
                rounded
              />
              <div class="d-flex flex-column ml-1">
                <div class="mb-1">
                  <h4 class="mb-0">
                    {{ userData.nom }} {{ userData.prenoms }}
                  </h4>
                  <span class="card-text">{{ userData.email }}</span>
                </div>

                <div class="d-flex flex-wrap">
                  <!-- <b-button variant="primary" v-b-modal.modal-update>
                    Modifier Image
                  </b-button> -->

                  <b-button
                    variant="outline-danger"
                    class="mr-1"
                    @click="deleteUser(userData.id)"
                  >
                    Supprimer
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>

          <b-col lg="10">
            <table
              class="mt-2 mt-xl-3 w-100 d-flex flex-column"
              style="gap: 8px"
            >
              <tr>
                <th class="pb-50">
                  <feather-icon icon="UserIcon" class="mr-75" />
                  <span class="font-weight-bold">Code : </span>
                </th>
                <td class="pb-50">
                  {{ userData.code }}
                </td>
              </tr>
              
              <tr>
                <th class="pb-50">
                  <feather-icon icon="StarIcon" class="mr-75" />
                  <span class="font-weight-bold">Role : </span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.status_user }}
                </td>
              </tr>
              <tr class="">
                <th class="pb-50 ">
                  <feather-icon icon="FlagIcon" class="mr-75" />
                  <span class="font-weight-bold">Adresse : </span>
                </th>
                <td class="pb-50">
                  {{ userData.localisation.formatted_address }}
                </td>
              </tr>
              <tr>
                <th>
                  <feather-icon icon="PhoneIcon" class="mr-75" />
                  <span class="font-weight-bold">Contact : </span>
                </th>
                <td>{{ userData.indicateur }} {{ userData.contact }}</td>
              </tr>
            </table>
          </b-col>

          <!-- debut Tab  -->

          <!-- Fin tab -->
        </b-row>
      </b-container>
    </b-card>


</template>

<script>
// import store from '@/store'
import { ref, onUnmounted,  } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BCard,BContainer
} from 'bootstrap-vue'
// import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import URL from '@/views/pages/request'
import axios from 'axios'
import { toast_destory } from '@/utils/qToast'
// import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
// import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BContainer,

    // Local Components
    UserViewUserInfoCard,
    // UserViewUserPlanCard,
    // UserViewUserPermissionsCard,

  },
  props: ['userData'],

  setup(props, {root}) {
    const destroy = () => {
      try {
        const id = {
          id: userData.id,
        };
        const config = {
          headers: {
            Accept: "application/json",
          },
        };
        axios
          .post(URL.EMPLOYE_DETROY, id, config)
          .then((response) => {
            if(response.data){
              response.data;
              toast_destory(
              this,
              'success',
              'top-right',
              'employé'
              );
              root.$router.push('/employes')
            }
          })
          .catch((error) => {
              console.log(error);
            
          });
      } catch (error) {
        console.log(error.type);
      }
    }

    return{
      destroy
    }
  }

}


</script>

<style>

</style>
